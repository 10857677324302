import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_single_page_content = _resolveComponent("single-page-content")!

  return (_openBlock(), _createBlock(_component_single_page_content, {
    "current-step-index": _ctx.currentStepIndex,
    steps: _ctx.steps,
    title: "Please register for 10DLC",
    text: "Register your brand and campaign to become 10DLC compliant and continue sending text messages to North America."
  }, {
    "white-block": _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }, 8, ["current-step-index", "steps"]))
}