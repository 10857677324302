
import {
  defineComponent, ref
} from 'vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import DlcRegistrationWrapper from '@/components/pages/services/numbers/dlcRegistration/DlcRegistrationWrapper.vue'
import industryVariant from '@/definitions/_general/_data/industryVariant'

export default defineComponent({
  components: {
    TmTooltip,
    TmButton,
    TmFormLine,
    DlcRegistrationWrapper,
  },
  setup() {
    const legalCompanyNameVariant = ref([
      {
        value: '2FA',
        tooltip: 'Two-Factor authentication, one-time use password, password reset',
      },
      {
        value: 'Marketing',
        tooltip: 'Any communication with marketing and/or  promotional content',
      },
      {
        value: 'Account notification',
        tooltip: 'All reminders, alerts, and notifications. (Examples include:  flight delayed, hotel booked, appointment reminders.)',
      },
      {
        value: 'Mixed',
        tooltip: 'Mixed messaging reserved for specific consumer service industry',
      },
      {
        value: 'Customer care',
        tooltip: 'All customer care messaging, including account management and support',
      },
      {
        value: 'Polling and voting',
        tooltip: 'Polling and voting',
      },
      {
        value: 'Delivery notification',
        tooltip: 'Information about the status of the delivery of a product or service',
      },
      {
        value: 'Public service announcement',
        tooltip: 'An informational message that is meant to raise the audience\'s awareness about an important issue',
      },
      {
        value: 'Fraud alert messaging',
        tooltip: 'Fraud alert notification',
      },
      {
        value: 'Security alert',
        tooltip: 'A notification that the security of a system, either software or hardware, has been compromised in some way and there is an action you need to take',
      },
      {
        value: 'Low volume mixed',
        tooltip: 'Low throughput, any combination of use-cases. Examples include: test, demo accounts',
      },
    ])
    const legalCompanyName = ref('2FA')

    const campaignVerticalVariant = ref(industryVariant)
    const campaignVertical = ref(industryVariant[0])

    const description = ref('')

    const sampleMessage = ref('')

    const campaignContentAttributes = ref([
      {
        title: 'Direct lending or loan arrangement',
        value: false,
        tooltip: 'Will this campaign include content related to direct lending or other loan arrangements?',
      },
      {
        title: 'Embedded link',
        value: false,
        tooltip: 'Are you using an embedded link of any kind? Note that public URL shorteners (bitly, tinyurl) are not accepted.',
      },
      {
        title: 'Embedded phone number',
        value: false,
        tooltip: 'Are you using an embedded phone number (except the required HELP information contact phone number)?',
      },
      {
        title: 'Affiliate marketing',
        value: false,
        tooltip: 'Is affiliate marketing being used or was used in the construction of this campaign?',
      },
      {
        title: 'Age-gated content',
        value: false,
        tooltip: 'Will this campaign include any age-gated content as defined by carrier and CTIA guidelines?',
      },
    ])

    const confirmation = ref(false)

    return {
      legalCompanyNameVariant,
      legalCompanyName,
      campaignVerticalVariant,
      campaignVertical,
      description,
      sampleMessage,
      campaignContentAttributes,
      confirmation,
    }
  },
})
