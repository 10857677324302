
import {
  defineComponent, ref
} from 'vue'
import SinglePageContent from '@/components/layout/SinglePageContent.vue'
import type { StepperStep } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    SinglePageContent,
  },
  props: {
    currentStepIndex: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const steps = ref<StepperStep[]>([
      { name: 'FAQ', to: 'base.services.numbers.10DLC.registration.step1' },
      { name: 'Brand details', to: 'base.services.numbers.10DLC.registration.step2' },
      { name: 'Campaign details', to: 'base.services.numbers.10DLC.registration.step3' },
    ])

    return {
      steps,
    }
  },
})
