const industryVariant = [
  { label: 'Agriculture', value: 'AGRICULTURE' },
  { label: 'Construction, Materials, and Trade Services', value: 'CONSTRUCTION' },
  { label: 'Education', value: 'EDUCATION' },
  { label: 'Energy and Utilities', value: 'ENERGY' },
  { label: 'Entertainment', value: 'ENTERTAINMENT' },
  { label: 'Financial Services', value: 'FINANCIAL' },
  { label: 'Gambling and Lottery', value: 'GAMBLING' },
  { label: 'Government Services and Agencies', value: 'GOVERNMENT' },
  { label: 'HR, Staffing or Recruitment', value: 'HUMAN_RESOURCES' },
  { label: 'Healthcare and Life Sciences', value: 'HEALTHCARE' },
  { label: 'Hospitality and Travel', value: 'HOSPITALITY' },
  { label: 'Information Technology Services', value: 'TECHNOLOGY' },
  { label: 'Insurance', value: 'INSURANCE' },
  { label: 'Legal', value: 'LEGAL' },
  { label: 'Manufacturing', value: 'MANUFACTURING' },
  { label: 'Media and Communication', value: 'COMMUNICATION' },
  { label: 'Non-profit Organization', value: 'NGO' },
  { label: 'Political', value: 'POLITICAL' },
  { label: 'Postal and Delivery', value: 'POSTAL' },
  { label: 'Professional Services', value: 'PROFESSIONAL' },
  { label: 'Real Estate', value: 'REAL_ESTATE' },
  { label: 'Retail and Consumer Products', value: 'RETAIL' },
  { label: 'Transportation or Logistics', value: 'TRANSPORTATION' },
]

export default industryVariant
